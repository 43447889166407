import TagManager from 'react-gtm-module';
import * as _ from 'lodash';
import GtmCommon from './gtmCommon';
import { getStore } from '../store';
import { FiltersBurial } from '../constants';
import { typeMonuments } from '../pages/family/FinitialChoice';
import { formatEmail } from './utils.service';

class GtmFamily extends GtmCommon {
  constructor(clientId) {
    super("family", "GTM-NVWXV2Q", clientId)
  }

  static getInstance
  static getInstance(clientId) {
    if (!this.instance) {
      this.instance = new GtmFamily(clientId);
    }
    return this.instance
  }

  getUser() {
    const familyUser = getStore().getState().familyService.user;
    if (!familyUser) return {id: "", logged: false};

    return {
      id: familyUser.id ? familyUser.id : "undefined",
      email: familyUser.email ? formatEmail(familyUser.email) : "",
      firstname: familyUser.name ? familyUser.name : "undefined",
      lastname: "undefined",
      logged: true,
    };
  }

  // sent two events ( 'product.list_display' &  'filter.apply')
  listOfMonuments(event, monuments, filter = false, loaded = true) {
    if (loaded) {
      this.clearObjectExceptAgrs();
      // ______ LIST ______
      if (!monuments) monuments = []
      const list = monuments.map(m => {
        // ______ FILTER ______
        let auxFilter = { mainFilter: "", secondFilter: "" }
        if ( filter == false ) {
          const mainFilter = typeMonuments.find(tm => tm.type == m.monumentType);
          const secondFilter = FiltersBurial.find(fb =>  m.keywords.includes(fb.filter));
          auxFilter = {
            mainFilter : mainFilter ? mainFilter.title : "",
            secondFilter : secondFilter ? secondFilter.label : ""
          }
        }
        return {
          id: m.productId,
          name: m.name,
          reference: m.reference,
          price_from: m.price || 0,
          granits: [
            m.graniteMain.name, 
            m.graniteSecondary.name
          ],
          styles: [filter.secondFilter || auxFilter.secondFilter], // second filter <--
          couleurs: [], // empty
          religions: [], // empty
          types: [filter.mainFilter || auxFilter.mainFilter], // main filter <--
          granit_main: m.graniteMain.name,
          flower_button: "", // empty
          v360_button: true //empty
        }
      })
      // ______ RESULT ______ 
      let obj = {
        event: event,
        filter: filter,
        page: {
          template: "configurateur",
          subtemplate: "liste produits"
        },
        list: {
          name: "Catalogue de l'Espace Famille",
          result_nb: list.length,
          page: 1, 
          search_keyword: "",
          search_category: filter ? filter.mainFilter : "",
          products: list, 
          articles: [], // empty
          projects: [] // empty
        },
        user: this.getUser()
      }
      if (event === "page.display") {
        obj = {
          ...obj, 
          partner: {
            id: getStore().getState().user.family
          }
        }
      }

      TagManager.dataLayer({ dataLayer: obj })
    }
  }

  // onselect monuments from catalog
  selectedMonument(monument, filter, monumentList) {
    this.clearObjectExceptAgrs();
    // List monuments 
    const list = monumentList.map(m => {
      return {
        id: m.productId,
        name: m.name,
        reference: m.reference,
        price_from: m.price || 0,
        granits: [
          m.graniteMain.name, 
          m.graniteSecondary.name
        ],
        styles: [filter.secondFilter], // second filter <--
        couleurs: [], // empty
        religions: [], // empty
        types: [filter.mainFilter], // main filter <--
        granit_main: m.graniteMain.name,
        flower_button: "", // empty
        v360_button: true //empty
      }
    })
    // Result
    const obj = {
      event: "product.click",
      page: {
        template: "configurateur",
        subtemplate: "liste produits"
      },
      list: {
        name: "Catalogue de l'Espace Famille",
        result_nb: list.length,
        page: 1, 
        search_keyword: "",
        search_category: filter ? filter.mainFilter : "",
        products: list, 
        articles: [], // empty
        projects: [] // empty
      },
      filter: filter,
      product: {
        id: monument.productId,
        name: monument.name,
        reference: monument.reference,
        price_from: 0, // empty
        granits: [
          monument.graniteMain.name, 
          monument.graniteSecondary.name
        ],
        styles: [filter.secondFilter], // second filter <--
        couleurs: [], // empty
        religions: [], // empty
        types: [filter.mainFilter], // main filter <--
        granit_main: monument.graniteMain.name,
        flower_button: "", // empty
        v360_button: true
      },
      user: this.getUser()
    }
  
    TagManager.dataLayer({ dataLayer: obj })
  }

  // on load
  loadConfiguration(config, categories, showBtnFlowers) {
    this.clearObjectExceptAgrs("list");
    const { granites } = config.options
    const { name, productId, reference, category, graniteMain, graniteSecondary } = config.configuration.monument
    // _________
    const granite_main = granites.find(g => g.reference === graniteMain)
    const granite_secondary = granites.find(g => g.reference === graniteSecondary)
    const category_obj = categories.find(c => c.categoryType === category)
    // const { search } = getStore().getState().catalog
    // const category_obj = categories.find(c => search.category && c.filter === search.category)
    // ____ user _____
    const obj = {
      event: "product.page_display",
      page: {
        template: "configurateur",
        subtemplate: "produit"
      },
      product: {
        id: productId,
        name: name || "", // get name
        reference: reference,
        price_from: 0, // empty
        granits: [
          granite_main ? granite_main.name : "", 
          granite_secondary ? granite_secondary.name : ""
        ],
        styles: [category_obj ? category_obj.label : ""], // second filter <--
        couleurs: [], // empty
        religions: [], // empty
        types: [], // empty main filter <--
        granit_main: granite_main.name,
        flower_button: showBtnFlowers,
        v360_button: true
      },
      partner: {
        id: getStore().getState().user.family
      },
      user: this.getUser()
    }

    TagManager.dataLayer({ dataLayer: obj })
  }
  
  // on click button Sidebar Action 
  /*endPointCustomize({ type }) {
    const obj = {
      event: "configurator.display",
      page: {
        template: "configurateur",
        subtemplate: "produit"
      },
      customize: {
        type: type,
        query: "", // empty
        filter: "" // empty
      },
    }
    
    TagManager.dataLayer({ dataLayer: obj })
  }*/
  
  // call to action button (configurator header)
  ctaOfConfiguration({ name, category, subcategory, type, from }, { template, subtemplate }, aux = {}, ...except) {
    this.clearObjectExceptAgrs(...except, "user", "partner");
    // ____ user _____
    const page = {
      page: {
        template: template || "configurateur",
        subtemplate: subtemplate || ""
      }
    }
    const obj = {
      ...aux,
      event: "cta.click",
      cta: {
        name: name,
        category: category,
        subcategory: subcategory,
        type: type,
        from: from
      },
      user: this.getUser()
    }
    let res = obj
    // set page obj from previews event if template == false && subtemplate == false
    if (template !== false && subtemplate !== false) {
      res = {
        ...obj,
        ...page
      }
    }
    
    TagManager.dataLayer({ dataLayer: res })
  }

  getUserWithFormDara(formData) {
    let user = this.getUser();

    if (user.logged) {
      // merge user with form data except firstname and lastname and email
      const { firstname, lastname, email, ...restFormData } = formData;
      return {
        ...user,
        ...restFormData
      };
    }

    // merge user with form data
    return {
      ...user,
      ...formData,
    }
  }

  // Quote asked
  quoteAskedConfiguration(formData) {
    this.clearObjectExceptAgrs("partner", "product", "user", "page");
    const obj = {
      event: "configurator.quote_ask",
      page: {
        template: "configurateur",
        subtemplate: "produit"
      },
      user: this.getUserWithFormDara(formData)
    }
    
    TagManager.dataLayer({ dataLayer: obj })
  }
  
  // Share configuration
  /*shareFromConfigurator(from) {
    const obj = {
      event: "configurator.share",
      quote: {
        social_network: from
      }
    }
    
    TagManager.dataLayer({ dataLayer: obj })
  }*/

  /**
   * __________ Customize configuration () __________ 
   *  configurator.search     =>  filter by search with input
   *  configurator.filter     =>  filter by options(btns)
   *  configurator.customize  =>  set customization
   */
  customizeConfigurator(event, subtemplate, {type, query, filter}, list = false, pattern = false) {
    this.clearObjectExceptAgrs("partner", "product", "user", "page", "list");
    let obj = {
      event: event,
      page: {
        template: "configurateur",
        subtemplate: subtemplate
      },
      customize: {
        type: type,
        query: query,
        filter: filter
      },
      user: this.getUser()
    }
    if (list) {
      obj = {
        ...obj,
        list: list
      }
    }
    if (pattern) {
      obj = {
        ...obj,
        pattern: pattern
      }
    }
    
    TagManager.dataLayer({ dataLayer: obj })
  }

  // SEND formulaire erreur
  formErrors({ name, category, type, message, form }) {
    this.clearObjectExceptAgrs("partner", "product", "user", "page");
    const obj = {
      event: "error",
      error: {
        name: name,
        category: category,
        type: type,
        message: message,
        form : form || ""
      },
      user: this.getUser()
    }
    
    TagManager.dataLayer({ dataLayer: obj })
  }

  /* 
    pages family space
    event :
      - project.list_display
      - project.page_display
      - compare.page_display
  */
  pageDispayFamilySpace(page, list = [], paramProject = null) {
    this.clearObjectExceptAgrs("partner", "user");
    let obj;
    const resListProjects = list.map(project => {
      return {
        id: project.id,
        name: project.name,
        spaceId: project.space_id,
        reference: project.config_id,
        price_from: project.price || 0
      }
    })
    const resListProducts = list.map(project => {
      const monument = _.get(project, "config.configuration.monument", {});
      const granites = _.get(project, "config.options.granites", []);
      const graniteMain = granites.find(g => g.reference == monument.graniteMain)
      const graniteSecondary = granites.find(g => g.reference == monument.graniteSecondary)
      return {
        id: monument.productId || "",
        name: monument.name,
        reference: monument.reference || "",
        price_from: 0, // empty
        granits: [
          graniteMain ? graniteMain.name : "" , 
          graniteSecondary ? graniteSecondary.name : ""
        ],
        styles: [], // second filter <--
        couleurs: [], // empty
        religions: [], // empty
        types: [], // main filter <--
        granit_main: graniteMain ? graniteMain.name : "",
        flower_button: "", // empty
        v360_button: true
      }
    })
    // __________________________________________________
    switch (page) {
      case 'projects':
        obj = {
          event: "project.list_display",
          list: {
            name: "Espace famille page projets",
            result_nb: resListProjects.length,
            page: 1, 
            search_keyword: "",
            search_category: "",
            products: resListProducts,
            articles: [], // empty
            projects: resListProjects
          },
          page: {
            template: "espace famille",
            subtemplate: "liste projets"
          },
        }
        break;
      case 'project':
        const monument = _.get(paramProject, "config.configuration.monument", {});
        const granites = _.get(paramProject, "config.options.granites", []);
        const graniteMain = granites.find(g => g.reference == monument.graniteMain)
        const graniteSecondary = granites.find(g => g.reference == monument.graniteSecondary)
        obj = {
          event: "project.page_display",
          project: {
            id: paramProject.id,
            name: paramProject.name,
            spaceId: paramProject.space_id,
            reference: paramProject.config_id,
            price_from: paramProject.price || 0
          },
          product: {
            id: monument.productId,
            name: monument.name,
            reference: monument.reference,
            price_from: 0, // empty
            granits: [
              graniteMain ? graniteMain.name : "" , 
              graniteSecondary ? graniteSecondary.name : ""
            ],
            styles: [], // second filter <--
            couleurs: [], // empty
            religions: [], // empty
            types: [], // main filter <--
            granit_main: graniteMain ? graniteMain.name : "",
            flower_button: "", // empty
            v360_button: true
          },
          page: {
            template: "espace famille",
            subtemplate: "projet"
          }
        }
        break;
      case 'compare':
        obj = {
          event: "compare.page_display",
          list: {
            name: "Espace famille page comparateur",
            result_nb: resListProjects.length,
            page: 1, 
            search_keyword: "",
            search_category: "",
            products: resListProducts,
            articles: [], // empty
            projects: resListProjects
          },
          page: {
            template: "espace famille",
            subtemplate: "page comparateur"
          }
        }
        break;
    }

    const res = {
      ...obj,
      user: this.getUser(),
      partner: {
        id: getStore().getState().user.family
      },
    }

    TagManager.dataLayer({ dataLayer: res })
  }

  // Select project to compare
  selectedProjectsCompare(list) {
    this.clearObjectExceptAgrs("partner", "user");
    const resListProject = list.map(project => {
      return {
        id: project.id,
        name: project.name,
        spaceId: project.space_id,
        reference: project.config_id,
        price_from: project.price || 0
      }
    })
    const resListProducts = list.map(project => {
      const monument = _.get(project, "config.configuration.monument", {});
      const granites = _.get(project, "config.options.granites", []);
      const graniteMain = granites.find(g => g.reference == monument.graniteMain)
      const graniteSecondary = granites.find(g => g.reference == monument.graniteSecondary)
      return {
        id: monument.productId || "",
        name: "",
        reference: monument.reference || "",
        price_from: 0, // empty
        granits: [
          graniteMain ? graniteMain.name : "" , 
          graniteSecondary ? graniteSecondary.name : ""
        ],
        styles: [], // second filter <--
        couleurs: [], // empty
        religions: [], // empty
        types: [], // main filter <--
        granit_main: graniteMain ? graniteMain.name : "",
        flower_button: "", // empty
        v360_button: true
      }
    })
    const obj = {
      event: "compare.add",
      list: {
        name: "Espace famille page comparateur",
        result_nb: resListProject.length,
        page: 1, 
        search_keyword: "",
        search_category: "",
        products: resListProducts,
        articles: [], // empty
        projects: resListProject
      },
      page: {
        template: "espace famille",
        subtemplate: "popup page comparateur"
      },
      user: this.getUser(),
      partner: {
        id: getStore().getState().user.family
      },
    }

    TagManager.dataLayer({ dataLayer: obj });
  }

  // Page ma famille
  familySpaceMyFamily() {
    this.clearObjectExceptAgrs("partner", "user");
    const obj = {
      event: "myfamily.page_display",
      page: {
        template: "espace famille",
        subtemplate: "ma famille"
      },
      user: this.getUser(),
      partner: {
        id: getStore().getState().user.family
      },
    }

    TagManager.dataLayer({ dataLayer: obj })
  }

  // Page ma famille
  /*
  familySpaceMyFamily() {
    this.clearObjectExceptAgrs("partner", "user");
    const obj = {
      event: "myfamily.page_display",
      page: {
        template: "espace famille",
        subtemplate: "ma famille"
      },
      user: this.getUser(),
      partner: {
        id: getStore().getState().user.family
      },
    }

    TagManager.dataLayer({ dataLayer: obj })
  }*/

  // Clear the previous object
  clearObjectExceptAgrs(...args) {
    let obj = {
      page: null,
      filter: null,
      partner: null,
      list: null,
      cta: null,
      customize:null,
      product: null,
      quote: null,
      user: null,
      error: null,
      project: null
    } 
    // Attributs to not reset
    args.map(arg => {
      delete obj[arg]
    })
    // Send data
    TagManager.dataLayer({ 
      dataLayer: obj
    })
  }
}
export const gtmFamilyInit = clientId => GtmFamily.getInstance(clientId);
export const gtmFamily = () => GtmFamily.getInstance();