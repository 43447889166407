import React, {Component} from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as uiActions from '../../../actions/uiActions';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions' ;
import { debounce } from 'lodash';
import {CompletionLine} from './CompletionLine'
import { isConfigRequested } from '../../../services/configurationWarningChecks';
class AdditionalLineFormComponent extends Component {

  constructor(props) {
    super(props);
    
    this.debouncedUpdateTitle = debounce(this.updateTitle, 300);
    this.debouncedUpdateDescription = debounce(this.updateDescription, 300);
  }

  hasTextInput = false;
  autoFocus = (inputRef)=> {
    if (!this.hasTextInput && inputRef) {
      inputRef.focus()
    }
    this.hasTextInput = !!inputRef
  }

  updateTitle = (index, value) => {
    this.props.actions.updateAdditionalLine(index, 'title', value);
  }

  updateDescription = (index, value) => {
    this.props.actions.updateAdditionalLine(index, 'description', value);
  }

  componentWillUnmount() {
    this.debouncedUpdateTitle.cancel();
    this.debouncedUpdateDescription.cancel();
  }

  render() {
    const {
      line,
      index,
      actions,
      isEditing,
      uiActions,
      canChangePrice,
      isDetailsModal
    } = this.props;

    function onEdit() {
      if (isEditing) {
        uiActions.setEditedLineIndex(null);
      } else {
        uiActions.setEditedLineIndex(index);
      }
    }
    function onRemove() {
      actions.removeItem('additional.lines', index)
    }
    if( line.title == "prestgrav" ){
      return ""
    }

    return (
      <CompletionLine
        editItem={!isConfigRequested(false) && onEdit}
        removeItem={!isConfigRequested(false) && onRemove}
        className="additionalLineFormContainer"
        price={line.price}
        allowNegative={true}
        priceChange={canChangePrice && ((p)=>actions.updatePrice('additional.lines.' + index, p))} 
        isDetailsModal={isDetailsModal} 
        isAddLine={true} >
        {this.renderLineContent()}
      </CompletionLine>
    )
  }

  renderLineContent() {
    const {
      line,
      index,
      isEditing,
      uiActions,
      actions
    } = this.props;

    let disabled = isConfigRequested(false);
    let classDisabled = disabled ? "disabled" : "" 

    if (isEditing) {
      return (
        <div className="AdditionalLineForm">
          <input
            ref={this.autoFocus}
            className="AdditionalTitle"
            type="text"
            defaultValue={line.title}
            onChange={(e) => this.debouncedUpdateTitle(index, e.target.value)}
            placeholder="Titre de la ligne (remise, forfait, pose ...)" 
            disabled={disabled} />

          <textarea
            className="AdditionalDescription"
            type="text"
            defaultValue={line.description}
            onChange={(e) => this.debouncedUpdateDescription(index, e.target.value)}
            placeholder="Commentaire supplémentaire"
            disabled={disabled} />

          <button
            className={`button-primary button-small text-cta ${classDisabled}`}
            disabled={disabled}
            onClick={()=>uiActions.setEditedLineIndex(null)}>
            <span>Valider</span>
          </button>
        </div>
      )
    } else {
      return (
        <div className="additionalLineDescription">
          <p><strong>{line.title || '<aucun titre de ligne>'}</strong></p>
          <p><em>{(line.description || '<aucune description>').split(/\r?\n/img).map((text, i) => (<span key={i}>{text}<br /></span>))}</em></p>
        </div>
      )

    }
  }

}

export const AdditionalLineForm = connect(
  (state, props) => ({
    line: state.configurator.current.configuration.additional.lines[props.index],
    isEditing: props.index === state.ui.customLineEdited
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  })
)(AdditionalLineFormComponent);


