import React, {Component} from 'react';
import {connect} from 'react-redux';
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { post,get } from '../../services/api.service';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import * as uiActions from '../../actions/uiActions';
import * as familySpaceActions from '../../actions/familySpaceActions';
import { Row, Modal, Button, Form, FormGroup, Label, Input, FormFeedback, Col } from "reactstrap";
import { Group299 } from '../../components/svg';
import * as _ from "lodash";
import { isGpgFamily, getDesignTypeConfiguration, emailRex,isGpgpFamilyConfig, formatEmail, formatToE164 } from '../../services/utils.service';
import { isSmartphone } from "../../services/domFunctions";
import { FrenchAddress } from "../../components/FrenchAddress";
import { getFamilyInfo } from "../family_space/utils/utils";
import benoit from "../../images/benoit-gpg-granit.png";
import marbrier from "../../images/marbrier-conseil-atelier.png";
import { gtmFamily } from '../../services/gtmFamily';
import Select from 'react-select';

const countriesWithPhoneCodeAndIso = [
  { value: '+33', label: 'France +33', countryiso: 'FR' },
  { value: '+32', label: 'Belgique +32', countryiso: 'BE' },
  { value: '+41', label: 'Suisse +41', countryiso: 'CH' },
  { value: '+352', label: 'Luxembourg +352', countryiso: 'LU' },
  { value: '+39', label: 'Italie +39', countryiso: 'IT' },
  { value: '+49', label: 'Allemagne +49', countryiso: 'DE' },
  { value: '+34', label: 'Espagne +34', countryiso: 'ES' }
];

class FquoteComponent extends Component {

  state = {
    help:false,validate:{email:true,phone:true},logo:false,
    phone:"",email:"",    message:"",firstname:"",lastname:"",
    town:"", townInfo:null,foreignTown:false, suppliers:[],
    sendingMail:false,mapModal:false,errorSentMail:false,
    showForm: false,notifSend: false,emailSent: false,
    selectTowns:false, countries: countriesWithPhoneCodeAndIso, selectedCountry: countriesWithPhoneCodeAndIso[0]
  };

  async componentDidMount () {
    if (this.props.showAskForm) {
      //When we show the quote form we should hide the prices list
      this.hidePriceDetails();
    }
    if (this.props.config && this.props.config.configuration.newfamilyInfos && this.props.config.configuration.newfamilyInfos.firstname){
      this.fillInInfo(this.props.config.configuration.newfamilyInfos);
    } else {
      const infoFamily = await getFamilyInfo();

      if (infoFamily) {
        this.fillInInfo(infoFamily);
      } else if (this.props.familyService && this.props.familyService.user && this.props.familyService.familySpace) {
        this.setState({
          lastname: this.props.familyService.user.name,
          email: this.props.familyService.user.email,
        });
      }
    }
    if (this.props.config && this.props.config.configuration.familySuppliers){
      this.setState({suppliers:this.props.config.configuration.familySuppliers})
    }
    if (this.props.openHelp){this.setState({help:true})};
    const {fromSpace,user} = this.props;
    const designtype = getDesignTypeConfiguration(this.props.config.configuration);
    const defaultImage = designtype === "B" ? marbrier: benoit;
    let image = false;
    let round = false;
    if (fromSpace && !isGpgpFamilyConfig(fromSpace)){
      image = await get(`/api/organizations/${fromSpace}/logo`);
    }
    else{
      if ((fromSpace && isGpgpFamilyConfig(fromSpace)) || isGpgpFamilyConfig()){
        round = true;
        image = defaultImage;
      }else{image = user.identity.organization.logo }
    }
    this.setState({logo:{image:image, theClass:round ? "":"square" }})

  }

  fillInInfo = data => {
    /*
    const  {firstname,lastname,phone,cityburial,postcodeburial,email} = data;
    const foreignTown = (postcodeburial || postcodeburial != "") ? false : true;
    if (!foreignTown) {
      this.setState({
        townInfo :{properties:{
          postcode: postcodeburial,
          city: cityburial
        }}
      })
    }
    this.setState({firstname,lastname,phone,email,foreignTown,town:`${postcodeburial} ${cityburial}`, showForm: !this.props.noForm});
    */
    const {firstname,lastname,email} = data;
    this.setState({firstname,lastname,email,showForm: !this.props.noForm});
  }

  validateEmail (email) {
    const { validate } = this.state;
    if (email) {
      validate.email = emailRex.test(email) ? true: false;
      this.setState({ validate })
    } else {
      validate.email = true
    }
  }

  validatePhone (phone) {
    const { validate, foreignTown } = this.state;
    if (phone){
      const phoneRex = foreignTown ? /^\d{9,10}$/ : /^0\d{9}$/;
      validate.phone = phoneRex.test(phone.replace(/\s/g, '')) ? true: false;
      this.setState({ validate })
    } else {
      validate.phone = true
    }
  }

  handleChange (e) {
    const {name,value} = e.target;
    let state = {...this.state};
    state[name] = value;
    this.setState(state);
  }

  changeSelectedCountry = (option) => {
    let isFrance = option.value === "+33";

    this.setState({selectedCountry: option, foreignTown:!isFrance, town:"", townInfo:null, suppliers:[] }, () => {
      this.validatePhone(this.state.phone);
    })
  }

  changeSelectedTown (option) {
    this.setState({town:option.label,townInfo:option.value});
    if (isGpgFamily()){
      this.manageClients(option.value);
    }
  }

  resetTown () {
    this.setState({town:""})
  }

  async manageClients (townInfo) {
    const tInfo = townInfo ? townInfo : this.state.townInfo;
    const longitude = _.get(tInfo,"geometry.coordinates") ? tInfo.geometry.coordinates[0]:0;
    const latitude = _.get(tInfo,"geometry.coordinates") ? tInfo.geometry.coordinates[1]:0;
    const address = longitude ? null : this.state.town;
    const data = {in_latitude : latitude,in_longitude : longitude,in_address: address,nb_max_clients:30,
                  first_radius_client_search:20, second_radius_client_search:0, };
    const results =  await post("/api/organizations/searcharound",data);
    this.setState({suppliers:results});

  }

  formatPhoneToSend = (phone) => {
    const {foreignTown, selectedCountry} = this.state;
    let phoneToSend = phone;
    if (foreignTown) {
      phoneToSend = `${selectedCountry.value}${phone.replace(/\s/g, '')}`;
    }

    return phoneToSend;
  }

  sendEmail = async () => {
    const {firstname,lastname,phone,email,town,validate,suppliers,townInfo } = this.state;
     if (firstname === "" || lastname === "" || town === "" || phone === ""){
       this.setState({errorMessage:" Un des champs obligatoire est manquant"});
       // GTM Errors
       gtmFamily().formErrors({
        form: "Demander un devis",
        name: "champ formulaire",
        category: "formulaire",
        type: "fonctionnel",
        message: "certains champs sont manquants"
       })
       // end GTM Errors
     }else{
      this.setState({errorMessage:"",sendingMail:true});
      if (validate.email && validate.phone && validate.phone && this.props.config.configuration){
        // On distingue le cas suppliers = false (pas GPG) de suppliers = tableau vide GPG mais pas de clients autour
        const suppliersFound = isGpgFamily() ? suppliers : false;
        const phoneToSend = this.formatPhoneToSend(phone)
        const postcode = townInfo && townInfo.properties && townInfo.properties.postcode ? townInfo.properties.postcode : "";
        const city = townInfo && townInfo.properties && townInfo.properties.city ? townInfo.properties.city : town;
        const params = {reference:this.props.config.configuration.reference,
                        familySuppliers: suppliersFound,
                        imageMonument:this.props.isoview.current,
                        familyconfig:{firstname:firstname,lastname:lastname,phone:phoneToSend,cityburial:city,
                        postcodeburial:postcode,email:email,emailSent:true}};
        const result = await post(`/api/family/send`, params);// send email via back-end       
        const sent = _.get(result, 'newfamilyInfos.emailSent');
        if (sent){
          if (this.props.fromSpace){this.props.sent();}
          // GTM
          gtmFamily().quoteAskedConfiguration({
            firstname: firstname,
            lastname: lastname,
            email: formatEmail(email),
            address: town,
            city: city || "undefined",
            postcode: postcode || "undefined",
            phone: formatToE164(this.state.selectedCountry.value, phone, this.state.foreignTown),
            country: this.state.foreignTown ? this.state.selectedCountry.countryiso : "FR",
            birthdate: "undefined",
          })
          // end GTM
        } else {
          // GTM Errors
          gtmFamily().formErrors({
            form: "Demander un devis",
            name: "Erreur interne du serveur",
            category: "formulaire",
            type: "fonctionnel",
            message: ""
           })
          // end GTM Errors
        } 
        sent ? this.setState({errorSentMail :false,sendingMail:false, emailSent:true,notifSend:true}) : this.setState({errorSentMail :true,sendingMail:false});
        if (!sent){_.delay(() => {this.setState({errorSentMail: false});}, 2500)}
        if (result.monument){
          this.props.updateActions.updateAllConfig(result);
          this.props.familySpaceActions.updateEmailSent({reference:result.reference, emailSent:true});
          /*if (this.props.closable){this.props.sent();this.props.onClose();}*/
        }
      }
      else {
        // GTM Errors
        gtmFamily().formErrors({
          form: "Demander un devis",
          name: "champ formulaire",
          category: "formulaire",
          type: "fonctionnel",
          message: "certains champs sont invalides"
         })
        // end GTM Errors
      }
     }
  }

  Close = field => {
    let newState={...this.state};
    newState[field] = false;
    return(<div className="Close" onClick={()=>this.setState(newState)}>
      <i className="icon material-icons">&#xE14C;</i>
    </div>  )
  };

  SupplierText = () => {
    let {suppliers} = this.state;
    if (!suppliers || suppliers.length === 0 || !isGpgFamily()) return (<div/>);

    if (suppliers.length ===1) {
        let {name,street,postal,city,contactPhone} =  suppliers[0];
        return (<div className="TheSupplier">
                    <div>{`à: ${name}`}</div>
                    <div>{street} </div>
                    <div>{`${postal} ${city}`}</div>
                    <div>{contactPhone}</div>
                </div>);
      } else {
        return (<div className="TheSupplier"> aux marbriers qui reprendront contact avec vous</div>);
      }
  }
  hidePriceDetails = () => {
    var parentDOM = document.getElementById("smallDetailsPop");
    if (parentDOM){
      const elem = parentDOM.getElementsByClassName('prices');
      if (elem && elem.length > 0){
        elem[0].style.display = 'none';
      }
      const helperElem = parentDOM.querySelector('.FquoteHelp');
      if (helperElem){
        helperElem.style.display = 'none';
      }
    }
  }
  quoteAsked = (showQuoteAskForm) => {
    this.setState({showForm: !showQuoteAskForm});
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "demander un devis",
      category: "devis",
      subcategory: "",
      type: "cta",
      from: "configuration tarif"
    }, { template: "configurateur", subtemplate: "produit" }, {}, "partner", "product", "user", "page")
    // end GTM
  }

  // ce morceau de code est extrait du composant "FrenchAddress"
  test=()=>true
  handeChangeTown=(e)=>{
    e.persist();
    if (!this.debouncedFn) {
      this.debouncedFn =  _.debounce(async () => {
        // let {value} = e.target;
        let value = this.state.town;
        if (this.test()){
          value = value.trim();
          if ((value.length >3 && isNaN(value)) || (value.length === 5 && !isNaN(value))){
          const postcode = !isNaN(value) && value.length === 5 ? value : null;
          if (!postcode && value.toLowerCase().includes("st ")){value = value.replace(/st/gi,"saint")}
          //const endChaine = postcode ? `codePostal=${postcode}`:`nom=${value}`;
          const url = postcode ? `https://api-adresse.data.gouv.fr/search/?q=${postcode}&postcode=${postcode}&limit=10`
                                :`https://api-adresse.data.gouv.fr/search/?q=${value}&city=${value}&limit=10`
          const infos = await fetch(url,{method:'GET',headers:{'Content-Type': 'application/json','Accept': 'application/json'}});                   
          const result = infos ? await infos.json() : null;
    
          if (result && result.features && result.features.length>0){
            const towns = result.features.map(item=>{
              const {name,city} = item.properties;
              const label = name === city ? `${item.properties.postcode} ${city}`: `${name} ${item.properties.postcode} ${city}`;
              return ({value:item, label})});
            this.setState({selectTowns:towns});
          }
        }
        }
      }, 300);
    }
    this.debouncedFn();
    this.setState({town:e.target.value});
  }
  selectPlace=(option)=>{
    this.setState({selectTowns:false,town:option.label});
    this.changeSelectedTown(option);
  }

  render () {
    const { help,logo, validate, errorMessage, foreignTown, errorSentMail, lastname, firstname, email, phone, town, showForm, notifSend,emailSent, selectTowns } = this.state;
    const sent = _.get(this.props.config, 'configuration.newfamilyInfos.emailSent');
    const { user, closable, onClose, fromClass, noForm,fromSpace, showAskForm } = this.props;
    const addClass = fromClass ? fromClass : "";
    const addButtonText = this.props.clientName ? `à ${this.props.clientName}` :"";
    let showQuoteAskForm = showForm || showAskForm
    if(!notifSend && emailSent && fromSpace){
      onClose();
    }
    if (showQuoteAskForm){
      //When we show the quote form we should hide the prices list to avoid scrolling.
      this.hidePriceDetails();
    }
    const {mainColor, fontColor} = this.props.adminConfig.config
  return (
    <div className={`Fquote ${addClass}`}>
      {closable  &&
        <div className="close-me" onClick={onClose}>
          <i className="icon material-icons">&#xE14C;</i>
        </div>
      }
      {(!noForm || showQuoteAskForm) &&
      // {logo && logo.image && <img className= {logo.theClass} src={logo.image} alt="Marbrier" /> }
          <Row className="FquoteTitle">
              <div className="titleText">Demander un devis à un artisan</div>
          </Row>
      }
      {(!sent && !notifSend) && (!noForm || showQuoteAskForm) &&
        <Form className="FquoteForm">
          <Row>
              <FormGroup>
                <Label className="required">Nom</Label>
                <Input name="lastname" required autocomplete= "on" placeholder="Ex: Dupond" defaultValue={lastname}  onChange={((e)=> this.handleChange(e))} />
              </FormGroup>
          </Row>
          <Row>
              <FormGroup>
                <Label className="required">Prénom</Label>
                <Input name="firstname" required autocomplete= "on" placeholder="Ex: Dominique" defaultValue={firstname}  onChange={((e)=> this.handleChange(e))}  />
              </FormGroup>
          </Row>

          <Row style={{display:"flex"}}>
              <FormGroup>
                <Label className="required">Numéro de téléphone</Label>
                <Row style={{display:"flex"}}>
                  <Col md="5" style={{
                    paddingLeft: "0",
                  }}>
                    <Select
                      className="FrenchAdressSelectTown"
                      options={this.state.countries}
                      defaultValue={this.state.countries[0]}
                      onChange={(option) => this.changeSelectedCountry(option)}
                      isSearchable={false}
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: '0.76rem',
                        }),
                        control: (base) => ({
                          ...base,
                          fontSize: '0.76rem',
                        }),
                        singleValue: (base) => ({
                          ...base,
                          fontSize: '0.76rem',
                        }),
                        option: (base) => ({
                          ...base,
                          fontSize: '0.76rem',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          padding: '0 5px',
                        }),
                      }}
                    />
                  </Col>
                  <Col md="7" style={{
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}>
                    <Input
                      name="phone"
                      autocomplete="on"
                      placeholder={foreignTown ? "Ex: 456 78 90 12" : "Ex: 06 12 34 56 78"}
                      onBlur={e => { this.validatePhone(e.target.value); }}
                      invalid={!validate.phone}
                      defaultValue={phone}
                      onInput={(e) => this.handleChange(e)}
                      style={{
                        marginBottom: "0",
                        marginTop: "0",
                        height: "100%"
                      }}
                    />
                    <FormFeedback>N° de tel non valide</FormFeedback>
                  </Col>
                </Row>
              </FormGroup>
          </Row>

          <Row>
            <FormGroup>
              <Label className="required">La commune pour la pose de votre monument ?</Label>
              {/* {!foreignTown && <FrenchAddress changeSelectedTown = {(option)=>this.changeSelectedTown(option)}
                              placeholder= "Ex: 35600, Angers" test={()=>true}/> }
              {foreignTown && <Input name="town" placeholder= "saisie libre"  required defaultValue={town} onChange={((e)=> this.handleChange(e))} />} */}

              {!foreignTown && 
                <div className="FrenchAddress">
                  <Input name="FrenchAdressTown" value={town} required placeholder={this.props.placeholder}  autocomplete= "on" onChange={this.handeChangeTown} 
                    onBlur={(e)=> {(e.target.value == "") && this.resetTown()}}
                  />
                  {selectTowns && <Select className="FrenchAdressSelectTown" options={selectTowns} menuIsOpen onChange={(option)=>{this.selectPlace(option)}}  isSearchable={false}/>}
                </div>
              }
              {foreignTown && <Input name="town" placeholder= "saisie libre"  required defaultValue={town}  autocomplete= "on" onChange={((e)=> this.handleChange(e))} />}

              {/* {!foreignTown ? 
                <Button 
                  className="OutsideFrance" 
                  style={{ "--f-main-color":mainColor }}
                  onClick= {()=> {
                    this.setState({foreignTown:true,town:"",townInfo:null,suppliers:[]}, () => {
                      this.validatePhone(phone);
                    })
                  } 
                } 
                >
                  Adresse en France
                </Button> : 
                <Button 
                  className="OutsideFrance" 
                  style={{ "--f-main-color":mainColor }}
                  onClick= {()=> {
                      this.setState({foreignTown:false,town:"",townInfo:null}, () => {
                        this.validatePhone(phone);
                      });
                    }
                  } >
                  Adresse hors France
                </Button>} */}

            </FormGroup>
          </Row>
          
          <Row>
              <FormGroup>
                <Label>L'adresse mail</Label>
                <Input type="email" required name="email"  autocomplete= "on" placeholder="Ex: monemail@gmail.com"
                  onBlur={e => this.validateEmail(e.target.value)}
                  invalid={!validate.email}
                  defaultValue={email}
                  onChange={((e)=> this.handleChange(e))} />
                <FormFeedback > Email non valide</FormFeedback>
              </FormGroup>
          </Row>
      <Button className="fsp-button FquoteSend btn-no-hover" style={{"--f-main-color": mainColor, "--f-font-color": fontColor, marginTop: "15px", marginBottom: "15px"}} onClick={(fields)=>this.sendEmail()}><span>ENVOYER MA DEMANDE{addButtonText}</span></Button>
          {errorSentMail && <div className="FquoteErrorMail">Erreur lors de l'envoi du mail merci de réessayer</div>}
          {errorMessage !== "" && <Label className="FquoteError">{errorMessage}</Label>}
        </Form>
      }
      {!showQuoteAskForm && !!noForm && !sent &&
        <Row>
          <Button className="FquoteAsk fsp-button" style={{"--f-main-color": mainColor, "--f-font-color": fontColor}} onClick={() => this.quoteAsked(showQuoteAskForm)}>Demander un devis</Button>
        </Row>
      }
      {(sent || notifSend) &&
        <div>
          <div className="messageSent">{fromSpace? "Demande envoyée" :"Demande envoyée"} {this.SupplierText()}</div>
          {!fromSpace ? <NavLink
            to={`/famille/${user.family}`}
          >
            <Button className="fsp-button FquoteSend btn-no-hover" style={{"--f-main-color": mainColor, "--f-font-color": fontColor}}>Démarrer une nouvelle configuration</Button>
          </NavLink> : <Button className="fsp-button FquoteSend btn-no-hover" onClick={onClose} style={{"--f-main-color": mainColor, "--f-font-color": fontColor}}>Fermer et revenir à mon espace</Button>}
        </div>
      }
      {/* <Modal isOpen={help && !isSmartphone()} toggle={()=>this.setState({help:false})} className={`FamilyHelpText ${addClass}`}>
        <div>Parce que chaque monument est unique, l’expérience d’un Marbrier est indispensable !</div>
        <div className="headText">Il est en mesure de vous :</div>
        <ul className="itemText">
          <li>Renseigner sur les dimensions spécifiques de votre monument</li>
          <li>Conseiller sur le choix du granit</li>
          <li>Accompagner jusqu’à la pose de votre monument</li>
        </ul>
       </Modal> */}
      <Modal isOpen={notifSend && !isSmartphone()} toggle={()=>this.setState({notifSend:false})} className={`FamilyHelpText FQuoteSuccess ${addClass}`}>
        <div className="arrow"/>
        <div>Félicitations !</div>
        <div className="headText">Demande de devis envoyée!</div>
      </Modal>

    </div>
  );

  }
}

export const Fquote = connect(state => ({
  isoview: state.isoView,
  familyService: state.familyService,
  adminConfig: state.adminConfig
}),
  dispatch => ({
    updateActions: bindActionCreators(updateConfigurationActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
    familySpaceActions: bindActionCreators(familySpaceActions, dispatch)
  })
)(FquoteComponent);
